/* ********* */
/*  GENERIC  */
/* ********* */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body::-webkit-scrollbar,
article::-webkit-scrollbar,
.no_scrollbar::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: none;
}

::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 15px;
}

html {
  min-height: -webkit-fill-available;
  height: 100%;
}

body {
  font-family: 'sans';
  overflow-x: hidden;
  cursor: url('./images/cursor.png'), auto;
}

canvas {
  display: block;
  border: none;
}

article {
  outline: none;
}

code {
  width: calc(100vw - 70vh);
  user-select: text;
}

@media (min-width: 600px) {
  section header {
    display: none;
  }

  section:hover header {
    display: flex;
  }
}

@media (max-width: 600px) {
  section header {
    display: flex;
  }
}

button:active {
  filter: brightness(0.95);
}

/* ******* */
/*  FONTS  */
/* ******* */

@font-face {
  font-family: 'sans';
  src: url('./fonts/px_grotesk_light.ttf');
}

@font-face {
  font-family: 'mono';
  src: url('./fonts/IBMPlexMono-Light.ttf');
}

@font-face {
  font-family: 'sun-moon';
  src: url('./fonts/sun-moon.ttf') format('truetype');
}

/* ******* */
/*  GRIDS  */
/* ******* */

#grid {
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(var(--rows-l), 25vw);
}

#grid section {
  position: relative;
  overflow: hidden;
}

#grid section article {
  background-color: var(--color-block);
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

@media (min-width: 600px) {
  #grid section:nth-of-type(9n + 1),
  #grid section:nth-of-type(9n + 5),
  #grid section:nth-of-type(9n + 9) {
    grid-column: auto / span 2;
  }
}

@media (min-width: 900px) and (max-width: 1300px) {
  #grid {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(var(--rows-m), 33vw);
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  #grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(var(--rows-s), 50vw);
  }
}

@media (max-width: 600px) {
  #grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(var(--rows-xs), 100vw);
  }

  #grid section {
    grid-column: span 1;
  }
}

.blend-mode-grid {
  display: grid;
  justify-items: center;
  align-items: center;
  gap: 20px;
}

/* ******** */
/*  INPUTS  */
/* ******** */

input[type='range'] {
  appearance: none;
  height: 38px;
  outline: none;
  border-radius: 30px;
  cursor: pointer;
  padding: 7px;
}

input[type='range']::-webkit-slider-thumb {
  appearance: none;
  width: 26px;
  height: 26px;
  background: black;
  border-radius: 30px;
  outline: none;
}

.color-ranges input[type='range'] {
  border: solid 0.5px var(--block-8-color);
  border-radius: 50px;
  background: none;
  padding: 0 0.5vw;
  height: 4vw;
  width: 85%;
  max-width: 300px;
}

.color-ranges input[type='range']::-webkit-slider-thumb {
  background: var(--block-8-color);
  height: 3.25vw;
  width: 3.25vw;
}

@media (max-width: 600px) {
  .color-ranges input[type='range'] {
    padding: 0 1.5vw;
    height: 16vw;
  }

  .color-ranges input[type='range']::-webkit-slider-thumb {
    height: 13vw;
    width: 13vw;
    border-radius: 50px;
  }
}

input[type='range'].range-input-thin {
  padding: 0;
  height: 1px;
  background: black;
}

input[type='range'].range-input-thin::-webkit-slider-thumb {
  height: 8px;
  width: 8px;
  border-radius: 50%;
}

input[type='text'].text-input-thin {
  font-family: 'sans';
}

input[type='text'].text-input-thin::placeholder {
  color: black;
  opacity: 0.5;
  font-size: 13.75px;
}

input[type='text'].text-input-thin::selection {
  background: white;
}

@media (min-width: 600px) and (max-width: 900px) {
  .color-ranges input[type='range'] {
    padding: 0 0.75vw;
    height: 8vw;
  }

  .color-ranges input[type='range']::-webkit-slider-thumb {
    height: 6.5vw;
    width: 6.5vw;
  }
}

@media (min-width: 900px) and (max-width: 1300px) {
  .color-ranges input[type='range'] {
    padding: 0 0.5vw;
    height: 5vw;
  }

  .color-ranges input[type='range']::-webkit-slider-thumb {
    height: 4vw;
    width: 4vw;
  }
}

#block-14 input[type='range'] {
  width: 50px;
  height: 10px;
  padding: 1px;
  border: solid 1px grey;
}

#block-14 input[type='range']::-webkit-slider-thumb {
  width: 6px;
  height: 6px;
}

#grid section article#glitch {
  overflow: visible;
}

#glitch input[type='range'] {
  padding: 0;
  height: 34px;
  width: 100%;
  padding: 0 13px;
  background-color: rgb(239, 239, 239);
  position: relative;
}

#glitch input[type='range']:before {
  content: '';
  height: 1px;
  width: 98px;
  left: 20px;
  background-color: black;
  position: absolute;
  top: 50%;
}

#glitch input[type='range']::-webkit-slider-thumb {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-top: 2px;
}

#glitch input[type='file'] {
  visibility: hidden;
}

#glitch #select {
  background-color: rgb(239, 239, 239);
  border: none;
}

#select {
  font-size: 13.5px;
  height: 34px;
}

input[type='color'] {
  -webkit-appearance: none;
  border: none;
  width: 15px;
  height: 15px;
  background: none;
  cursor: pointer;
}

input[type='color']::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type='color']::-webkit-color-swatch {
  border-radius: 50%;
  border: solid 0.1px rgb(175, 175, 175);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

#galaxy-grid input[type='range'] {
  background: var(--sanguine4);
  transform: scale(0.5);
  width: 60%;
}

#galaxy-grid input[type='range']::-webkit-slider-thumb {
  background: white;
}

/* ****** */
/*  TEXT  */
/* ****** */

#block-1 ::selection {
  background: black;
}

#block-10 ::selection {
  background: var(--blue1);
}

@media (min-width: 900px) and (max-width: 1300px) {
  .block-12.text {
    font-size: 5.5vw;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .block-12.text {
    font-size: 8vw;
  }
}

@media (max-width: 600px) {
  .block-12.text {
    font-size: 14vw;
  }
}

.block-20.text span {
  opacity: 0.3;
}

.block-20.text:hover span {
  opacity: 1;
}

#section-39 canvas {
  position: absolute;
  top: 0;
  left: 0;
}

/* ************ */
/*  ANIMATIONS  */
/* ************ */

.disco-top-right {
  animation: disco-top-right 2.5s linear infinite;
}

@keyframes disco-top-right {
  0% {
    background-position: var(--unit) calc(-1 * (var(--unit)));
  }
  100% {
    background-position: calc(var(--unit) * 3) calc(-1 * (var(--unit) * 3));
  }
}

.disco-top-left {
  animation: disco-top-left 2.5s linear infinite;
}

@keyframes disco-top-left {
  0% {
    background-position: calc(-1 * (var(--unit))) calc(-1 * (var(--unit)));
  }
  100% {
    background-position: calc(-1 * (var(--unit) * 3))
      calc(-1 * (var(--unit) * 3));
  }
}

.disco-bottom-left {
  animation: disco-bottom-left 2.5s linear infinite;
}

@keyframes disco-bottom-left {
  0% {
    background-position: calc(-1 * (var(--unit))) var(--unit);
  }
  100% {
    background-position: calc(-1 * (var(--unit) * 3)) calc(var(--unit) * 3);
  }
}

.disco-bottom-right {
  animation: disco-bottom-right 2.5s linear infinite;
}

@keyframes disco-bottom-right {
  0% {
    background-position: var(--unit) var(--unit);
  }
  100% {
    background-position: calc(var(--unit) * 3) calc(var(--unit) * 3);
  }
}

.arrows-top {
  transform: translateY(-4px);
}

.arrows-top.to-top {
  animation: arrows-to-top 0.5s alternate infinite;
}

@keyframes arrows-to-top {
  0% {
    transform: translateY(-4px);
  }
  100% {
    transform: translateY(-16px);
  }
}

.arrows-bottom {
  transform: translateY(4px);
}

.arrows-bottom.to-bottom {
  animation: arrows-to-bottom 0.5s alternate infinite;
}

@keyframes arrows-to-bottom {
  0% {
    transform: translateY(4px);
  }
  100% {
    transform: translateY(16px);
  }
}

.circle-pop {
  animation: circle-pop 0.2s linear;
}

@keyframes circle-pop {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(4);
  }
  100% {
    transform: scale(1);
  }
}

.circle-pop-disappear {
  animation: circle-pop-disappear 0.2s linear;
  width: 10px;
  height: 10px;
}

@keyframes circle-pop-disappear {
  0% {
    opacity: 1;
  }
  20% {
    transform: scale(3);
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
/* ********** */
/*  SPECIFIC  */
/* ********** */

.tag:hover {
  z-index: 2;
}

.outline_button:focus {
  box-shadow: 0px 0px 6px hsla(0, 0%, 0%, 0.5);
  outline: none;
}

.hover_shadow_cherry4:hover {
  box-shadow: 0 0 25px inset var(--cherry4);
}

#displacement-map {
  cursor: url('./images/red-dot.png'), auto;
}

#canvas-3d.drawing {
  cursor: url('./images/brush.png'), auto;
}

#canvas-3d.drawn {
  cursor: url('./images/cursor-move.png'), auto;
}
